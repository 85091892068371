// Moved AppTitle/AppName into environment files.

export const site_types: string[] = [
  'Commercial Site',
  'Leisure Site',
  'Major Project Development Site',
  'Managed Site',
  'Office',
  'Owned Site',
  'Residential Building Site',
  'Retail Development Site',
  'Retail Site',
  'School Site',
  'Serviced Site',
  'Shopping Centre',
  'Single Dwelling Site',
  'Warehouse',
  'Capital Works Project',
  'Maintenance',
  'Other'
];

export const site_statuses: string[] = [
  'Pre-Construction',
  'Under Construction',
  'Hand Over Complete',
  'Under Active Management',
  'Maintenance',
  'Archived',
  'On Hold'
];

